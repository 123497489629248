import React from 'react';
import PlanEditorContainer from './components/PlanEditor';
import { ViewContextProvider, View } from '../../src/contexts/ViewContext';
import { DrawingContextProvider } from '../../src/contexts/DrawingContext';

// eslint-disable-next-line import/prefer-default-export
export function PlanEditor(props: { seatConfigId: number }): JSX.Element {
  return (
    <ViewContextProvider value={[View.Contingent, View.SeatGroup]}>
      <DrawingContextProvider>
        <PlanEditorContainer {...props} />
      </DrawingContextProvider>
    </ViewContextProvider>
  );
}
