import React, { ReactElement, useCallback } from 'react';
import { DOMAIN_CONTEXT, useDomainContext } from '@mapado/js-component';
import { MpdCard, MpdIcon, MpdPopover, MpdRadio } from '@mapado/makeup';
import {
  $primaryBlue,
  $primaryOrange,
  $primaryGreen,
  // @ts-expect-error -- See https://github.com/microsoft/TypeScript/issues/33079#issuecomment-911893337
} from '@mapado/makeup/variables';
import cn from 'classnames';
import { List } from 'immutable';
import { useViewContext, View } from '../../contexts/ViewContext';
import SeatGroupList from '../SeatGroupList';
import ContingentEntityList from '../ContingentEntityList';
import {
  BOOKING_STATUS_AVAILABLE,
  BOOKING_STATUS_DISMISSED,
  BOOKING_STATUS_IN_CART,
  BOOKING_STATUS_IN_ORDER,
  BOOKING_STATUS_IN_RESERVATION,
  BOOKING_STATUS_SCANNED,
} from '../../utils/booking';
import { getIcon } from '../../utils/seatStyle';
import {
  contingentSelector,
  useSeatGroupSelector,
  useStockContingentSelector,
} from '../../utils/selectors';
import { useSeatingSelector } from '../../reducers/typedFunctions';
import { useTranslation } from '../../i18n';

type Props = {
  controls: ReactElement;
};

const SELECTED_SEAT = 'selected_seat';

const statusList = [
  BOOKING_STATUS_AVAILABLE,
  SELECTED_SEAT,
  BOOKING_STATUS_IN_ORDER,
  BOOKING_STATUS_IN_RESERVATION,
  BOOKING_STATUS_IN_CART,
  BOOKING_STATUS_DISMISSED,
  BOOKING_STATUS_SCANNED,
];

function TopToolbar({ controls }: Props) {
  const { t } = useTranslation();
  const { viewList, selectedView, setSelectedView } = useViewContext();
  const { currentDomain } = useDomainContext();
  const stockContingentList = useStockContingentSelector();
  const contingentList = useSeatingSelector(contingentSelector);
  const seatGroupList = useSeatGroupSelector() || List();
  const contingentEntityList = contingentList || stockContingentList;

  const getLabelView = useCallback((): string => {
    switch (selectedView) {
      case View.Contingent:
        return t('toolbar.view.contingent');
      case View.SeatGroup:
        return t('toolbar.view.seat_group');
      default:
        throw new Error(
          `Unable to get label for the selected view ${selectedView}.`
        );
    }
  }, [selectedView, t]);

  return (
    <div
      className={cn('mpd-seating__top-toolbar', {
        'mpd-seating__top-toolbar--minisite':
          currentDomain === DOMAIN_CONTEXT.MINISITE,
      })}
    >
      {currentDomain === DOMAIN_CONTEXT.DESK && (
        <MpdCard>
          <MpdPopover
            keepOpen
            icon="arrow-down"
            label={getLabelView()}
            labelClassName="mr1 pl1 pr1 small bold"
          >
            <div className="mb3">
              <h3 className="mb2">{t('seat.toolbar.display_view')}</h3>
              {viewList.includes(View.Contingent) && (
                <MpdRadio
                  name="radio-view"
                  className="mpd-seating__top-toolbar__radio mb1"
                  defaultChecked={selectedView === View.Contingent}
                  onClick={() => setSelectedView(View.Contingent)}
                >
                  {t('toolbar.view.contingent')}
                </MpdRadio>
              )}
              {viewList.includes(View.SeatGroup) && (
                <MpdRadio
                  name="radio-view"
                  className="mpd-seating__top-toolbar__radio"
                  defaultChecked={selectedView === View.SeatGroup}
                  onClick={() => setSelectedView(View.SeatGroup)}
                >
                  {t('toolbar.view.seat_group')}
                </MpdRadio>
              )}
            </div>
            <div className="mb3">
              <h3 className="mb2">{t('seat.toolbar.legend')}</h3>
              {statusList.map((status) => (
                <div key={status}>
                  <MpdIcon
                    icon={getIcon(status, false)}
                    color={getLegendColor(status)}
                    width="12"
                  />
                  <span className="ml2 small bold">
                    {t(`seat.status.${status}`)}
                  </span>
                </div>
              ))}
            </div>
            {selectedView === View.Contingent &&
              contingentEntityList &&
              contingentEntityList.size > 0 && (
                <div>
                  <h3 className="mb2">
                    {t('seat.toolbar.stockContingent.color')}
                  </h3>
                  <ContingentEntityList
                    contingentEntityList={contingentEntityList}
                  />
                </div>
              )}
            {selectedView === View.SeatGroup && seatGroupList.size > 0 && (
              <div>
                <h3 className="mb2">{t('seat.toolbar.seatGroup.color')}</h3>
                <SeatGroupList seatGroupList={seatGroupList} />
              </div>
            )}
          </MpdPopover>
        </MpdCard>
      )}
      {controls}
    </div>
  );
}

function getLegendColor(status: string): string {
  if (status === SELECTED_SEAT) {
    return $primaryOrange;
  }

  if (status === BOOKING_STATUS_SCANNED) {
    return $primaryGreen;
  }

  return $primaryBlue;
}

export default TopToolbar;
