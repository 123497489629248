import React, { memo, createContext, useContext, useEffect } from 'react';
import { Contract } from 'mapado-ticketing-js-sdk';
import { MpdLoader } from '@mapado/makeup';
import SeatingPlanPurchaseContainer from './containers/Main/SeatingPlanPurchaseContainer';
import CartReplacementContainer from './containers/Main/CartReplacementContainer';
import OrderReplacementContainer from './containers/Main/OrderReplacementContainer';
import OrderViewerContainer from './containers/Main/OrderViewerContainer';
import SeatingPlanViewerContainer from './containers/Main/SeatingPlanViewerContainer';
import LogicalSeatConfigAdminContainer from './containers/Main/LogicalSeatConfigAdminContainer';
import OrderManagementContainer from './containers/Main/OrderManagementContainer';
import { i18n } from './i18n';
import MultiContextPlan, {
  MultiContextPlanProps,
} from './components/Main/MultiContextPlan';
import MomentInstance from './MomentInstance';
import TicketingSdkInstance from './TicketingSdkInstance';
import StockManagementComponent, {
  StockManagementProps,
} from './components/Main/StockManagement';
import { OrderManagementProps } from './components/Main/OrderManagement';
import { SeatingPlanPurchaseProps } from './components/Main/SeatingPlanPurchase';
import {
  ViewContextProvider,
  View,
  ViewListType,
} from './contexts/ViewContext';
import { SeatingPlanViewerProps } from './components/Main/SeatingPlanViewer';
import { OrderReplacementProps } from './components/Main/OrderReplacement';
import { CartReplacementProps } from './components/Main/CartReplacement';
import { LogicalSeatConfigAdminProps } from './components/Main/LogicalSeatConfigAdmin';
import { OrderViewerProps } from './components/Main/OrderViewer';
import { DrawingContextProvider } from './contexts/DrawingContext';
import seatingReducer from './reducers';
import { resetState } from './actions/SeatActions';
import {
  useSeatingDispatch,
  useSeatingSelector,
} from './reducers/typedFunctions';
import { setContract } from './actions/AppActions';

const BaseContext = createContext(false);

type BaseProps = {
  children: React.ReactElement;
  // eslint-disable-next-line react/require-default-props
  viewList?: ViewListType;
};

type BaseWithContractProps = BaseProps & {
  contract: Contract;
};

const Base = memo(function Base({
  children,
  viewList = [View.Contingent, View.SeatGroup],
}: BaseProps): React.ReactElement {
  const hasBaseAlready = useContext(BaseContext);

  const dispatch = useSeatingDispatch();

  useEffect(() => {
    return () => {
      if (!hasBaseAlready) {
        dispatch(resetState());
      }
    };
  }, [dispatch, hasBaseAlready]);

  if (hasBaseAlready) {
    // avoid recreating sub-stores in Multi-context view
    return children;
  }

  return (
    <BaseContext.Provider value>
      <ViewContextProvider value={viewList}>
        <DrawingContextProvider>{children}</DrawingContextProvider>
      </ViewContextProvider>
    </BaseContext.Provider>
  );
});

function BaseWithContract({
  contract,
  children,
  viewList = [View.Contingent, View.SeatGroup],
}: BaseWithContractProps): React.ReactElement {
  const stateContract = useSeatingSelector((state) =>
    state.seating.get('contract')
  );

  const dispatch = useSeatingDispatch();

  useEffect(() => {
    dispatch(setContract(contract));
  }, [contract, dispatch]);

  if (!stateContract) {
    return (
      <div className="mpd-seating__app--is-loading">
        <MpdLoader />
      </div>
    );
  }

  return <Base viewList={viewList}>{children}</Base>;
}

function SeatingPlanPurchase(
  props: SeatingPlanPurchaseProps
): React.ReactElement {
  return (
    <Base>
      <SeatingPlanPurchaseContainer {...props} />
    </Base>
  );
}

SeatingPlanPurchase.displayName = 'SeatingPlanPurchase';

function CartReplacement(props: CartReplacementProps): React.ReactElement {
  return (
    <Base>
      <CartReplacementContainer {...props} />
    </Base>
  );
}

CartReplacement.displayName = 'CartReplacement';

function OrderReplacement(props: OrderReplacementProps): React.ReactElement {
  return (
    <Base>
      <OrderReplacementContainer {...props} />
    </Base>
  );
}

OrderReplacement.displayName = 'OrderReplacement';

function LogicalSeatConfigAdmin(
  props: LogicalSeatConfigAdminProps & { contract: Contract }
): React.ReactElement {
  const { contract, ...rest } = props;

  return (
    <BaseWithContract contract={contract}>
      <LogicalSeatConfigAdminContainer {...rest} />
    </BaseWithContract>
  );
}

LogicalSeatConfigAdmin.displayName = 'LogicalSeatConfigAdmin';

function StockManagement(props: StockManagementProps): React.ReactElement {
  return (
    <Base>
      <StockManagementComponent {...props} />
    </Base>
  );
}

StockManagement.displayName = 'StockManagement';

/**
 * @deprecated The order viewer can be replaced by the `CartReplacement` component.
 * In this case, we need to check if we need to hide the `CartTile` on the desk.
 * Also need to check selectableSeatId list since minisite does not allow replacement in contingent different from the ticketPrice contingent
 */
function OrderViewer(props: OrderViewerProps): React.ReactElement {
  return (
    <Base viewList={[View.SeatGroup]}>
      <OrderViewerContainer {...props} />
    </Base>
  );
}

OrderViewer.displayName = 'OrderViewer';

function SeatingPlanViewer(props: SeatingPlanViewerProps): React.ReactElement {
  return (
    <Base>
      <SeatingPlanViewerContainer {...props} />
    </Base>
  );
}

SeatingPlanViewer.displayName = 'SeatingPlanViewer';

function OrderManagement(props: OrderManagementProps): React.ReactElement {
  return (
    <Base>
      <OrderManagementContainer {...props} />
    </Base>
  );
}

OrderManagement.displayName = 'OrderManagement';

function MultiContextPlanWithBase(
  props: MultiContextPlanProps
): React.ReactElement {
  return (
    <Base>
      <MultiContextPlan {...props} />
    </Base>
  );
}

export {
  MomentInstance,
  TicketingSdkInstance,
  SeatingPlanPurchase,
  CartReplacement,
  OrderReplacement,
  OrderViewer,
  SeatingPlanViewer,
  OrderManagement,
  LogicalSeatConfigAdmin,
  StockManagement,
  MultiContextPlanWithBase as MultiContextPlan,
  i18n,
  seatingReducer,
};
