import React, { ReactNode, useState } from 'react';
import {
  assertRelationIsListOfObject,
  assertRelationIsNullOrObject,
  assertRelationIsObject,
  assertRelationIsString,
  AvailableSeat,
  Cart,
  CART_TYPE,
  TicketPrice,
} from 'mapado-ticketing-js-sdk';
import { List } from 'immutable';
import { MpdPopover } from '@mapado/makeup';
import {
  FormattedTicketPriceFacialValue,
  getEntityId,
} from '@mapado/js-component';
import cn from 'classnames';
import SidebarTemplate from './SidebarTemplate';
import '../../styles/components/PlanPurchaseSidebar.scss';
import { cartItemIsForEventDate } from '../../utils/cart';
import { getSeatGroupLabelMap } from '../../utils/selectors';
import { useSeatingSelector } from '../../reducers/typedFunctions';
import { useTranslation } from '../../i18n';

interface Props {
  cart: Cart | null;
  eventDateId: number;
  getTicketPriceListBySeatGroupAndStockContingent: (
    seatGroup: string,
    stockContingent: string | null
  ) => List<TicketPrice>;
  handleChangeTicketPrice: (
    availableSeat: AvailableSeat,
    oldTicketPrice: TicketPrice,
    newTicketPrice: TicketPrice
  ) => void;
  sidebarPrevAction?: ReactNode;
  sidebarNextAction?: ReactNode;
  locale: string;
}

function SeatingPlanPurchaseSidebar({
  cart,
  getTicketPriceListBySeatGroupAndStockContingent,
  handleChangeTicketPrice,
  sidebarPrevAction,
  sidebarNextAction,
  eventDateId,
  locale,
}: Props) {
  const { t } = useTranslation();
  const [forceVisibleSidebar, setForceVisibleSidebar] = useState(false);
  const seatGroupLabelMap = useSeatingSelector(getSeatGroupLabelMap);

  if (!cart) {
    return null;
  }

  assertRelationIsListOfObject(cart.cartItemList, 'cart.cartItemList');

  const cartItemList = cart.cartItemList.filter((cartItem) =>
    cartItemIsForEventDate(cartItem, eventDateId)
  );

  if (!cartItemList.size) {
    return null;
  }

  const sidebarClassNames = cn('plan-purchase-sidebar', {
    'plan-purchase-sidebar--force-visible': forceVisibleSidebar,
  });

  return (
    <>
      {!forceVisibleSidebar && (
        <div className="plan-purchase-responsive-bar">
          {sidebarPrevAction}
          <button
            type="button"
            className="mpd-btn mpd-btn--primary"
            onClick={() => setForceVisibleSidebar(true)}
          >
            {t('next')}
          </button>
        </div>
      )}
      <SidebarTemplate
        className={sidebarClassNames}
        action={
          (sidebarPrevAction || sidebarNextAction) && (
            <>
              {!forceVisibleSidebar ? (
                sidebarPrevAction
              ) : (
                <button
                  type="button"
                  className="mpd-btn"
                  onClick={() => setForceVisibleSidebar(false)}
                >
                  {t('seating.plan_purchase.back_plan')}
                </button>
              )}
              {sidebarNextAction}
            </>
          )
        }
      >
        <div className="plan-purchase-sidebar__ticket-prices">
          <h3 className="mb3 txtcenter">
            {t('seating.plan_purchase.sidebar.title')}
          </h3>
          {cartItemList.map((cartItem) => {
            const { ticketPrice, stockContingent } = cartItem;

            assertRelationIsListOfObject(
              cartItem.availableSeatList,
              'cartItem.availableSeatList'
            );
            assertRelationIsObject(ticketPrice, 'ticketPrice');

            const availableSeat = cartItem.availableSeatList.first();

            if (!availableSeat) {
              // ignore cart items with no available seats (for example ticket price that does not generate a ticket)
              return null;
            }

            const { seat, seatGroup } = availableSeat;

            assertRelationIsString(seatGroup, 'availableSeat.seatGroup');
            assertRelationIsObject(seat, 'availableSeat.seat');

            const ticketPriceList =
              getTicketPriceListBySeatGroupAndStockContingent(
                seatGroup,
                getEntityId(stockContingent)
              );
            // @ts-expect-error -- info is a Map<string, unknown> and not a Record<string, unknown>
            const seatLabel = seat.info?.get('label');

            assertRelationIsNullOrObject(
              cartItem.stockContingent,
              'cartItem.stockContingent'
            );

            const labelInfos = [
              seatLabel ? `${t('seating.caption.seat')} ${seatLabel}` : null,
              seatGroupLabelMap.get(seatGroup),
              cartItem.stockContingent?.name,
            ].filter(Boolean);

            return (
              <div key={cartItem['@id']} className="mb2">
                {labelInfos && (
                  <span className="small bold mpd-color-gray">
                    {labelInfos.join(', ')}
                  </span>
                )}
                <MpdPopover
                  className="mpd-card ticket-price"
                  displayOnLeft
                  icon="arrow-down"
                  label={
                    <>
                      <span className="block mpd-color-black">
                        {ticketPrice.name}
                      </span>
                      <span className="h3-like">
                        <FormattedTicketPriceFacialValue
                          ticketPrice={ticketPrice}
                          locale={locale}
                        />
                      </span>
                    </>
                  }
                  labelClassName="mr2"
                >
                  {ticketPriceList
                    // remove ticket prices that will not generate ticket (you can not assign a seat on them)
                    // and remove options ticket prices
                    .filter((tp) => {
                      assertRelationIsObject(
                        tp.bookingTrack,
                        'tp.bookingTrack'
                      );

                      return (
                        tp.willGenerateTicket === true &&
                        tp.bookingTrack.cartType !== CART_TYPE.OPTION
                      );
                    })
                    .map((tp) => (
                      <button
                        key={tp.get('@id')}
                        type="button"
                        className="mpd-popover__link"
                        disabled={tp.remainingStock === 0}
                        onClick={() =>
                          handleChangeTicketPrice(
                            availableSeat,
                            ticketPrice,
                            tp
                          )
                        }
                      >
                        <div>{tp.name}</div>
                        <span className="h3-like">
                          <FormattedTicketPriceFacialValue
                            ticketPrice={tp}
                            locale={undefined}
                          />
                        </span>
                      </button>
                    ))}
                </MpdPopover>
              </div>
            );
          })}
        </div>
      </SidebarTemplate>
    </>
  );
}

export default SeatingPlanPurchaseSidebar;
