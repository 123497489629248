import { createSelector } from 'reselect';
import { List, Map, Set } from 'immutable';
import { getEntityId } from '@mapado/js-component';
import {
  Booking,
  Order,
  PagedCollection,
  SeatGroup,
  StockContingent,
  assertRelationIsObject,
  assertRelationIsString,
  AvailableSeat,
  Contingent,
  AVAILABLE_SEAT_STATUS_AVAILABLE,
  AVAILABLE_SEAT_STATUS_DISMISSED,
  assertRelationIsNullOrObject,
  assertRelationIsDefined,
  Cart,
} from 'mapado-ticketing-js-sdk';
import {
  availableSeatModelSelector,
  eventDateSelector,
  getAvailableSeatMappedBySeatId,
  getAvailableSeatModelMappedBySeatId,
  seatSelector,
} from './selectors';
import {
  getAvailableSeatListFromCart,
  getAvailableSeatListFromOrder,
  getPossibleSeatGroupListForBookedSeat,
} from './memoized';
import {
  AvailableSeatModelType,
  AvailableSeatType,
  SeatType,
} from '../propTypes';
import type { RootState } from '../reducers';
import {
  BOOKING_STATUS_AVAILABLE,
  BOOKING_STATUS_DISMISSED,
  BOOKING_STATUS_IN_CART,
  isBooking,
  isCart,
  isOrder,
} from './booking';
import { useSeatingSelector } from '../reducers/typedFunctions';

export function getAvailableSeatListFromBooking(
  booking: undefined | null | Cart | Order | Booking
): List<AvailableSeat> {
  let cart: Cart | null = null;
  let order: Order | null = null;

  if (isBooking(booking)) {
    if (booking.cart) {
      assertRelationIsObject(booking.cart, 'booking.cart');
      cart = booking.cart;
    }

    if (booking.order) {
      assertRelationIsObject(booking.order, 'booking.order');
      order = booking.order;
    }
  } else if (isCart(booking)) {
    cart = booking;
  } else if (isOrder(booking)) {
    order = booking;
  }

  if (cart !== null) {
    return getAvailableSeatListFromCart(cart);
  }

  if (order !== null) {
    return getAvailableSeatListFromOrder(order);
  }

  return List<AvailableSeat>();
}

export function getSelectableSeatIdSetForReplacement(
  state: RootState,
  seatIdSet: Set<string>,
  cartOrOrder: Cart | Order | null
): Set<string> {
  if (!cartOrOrder) {
    return Set();
  }

  const availableSeatMappedBySeat = getAvailableSeatMappedBySeatId(state);

  const availableSeatList = getAvailableSeatListFromBooking(cartOrOrder);

  const selectableSeatIdSet = seatIdSet.filter((seatId: string) => {
    const availableSeat = availableSeatMappedBySeat.get(seatId);
    const seatBookingStatus = availableSeat
      ? availableSeat.bookingStatus
      : null;

    if (seatBookingStatus === BOOKING_STATUS_AVAILABLE) {
      return true;
    }

    return availableSeatList.find(
      // @ts-expect-error -- custom serializer typing error
      (avs: AvailableSeatType) => avs.seat['@id'] === seatId
    );
  });

  return selectableSeatIdSet;
}

export function getSelectableSeatIdSetForOrderViewer(
  state: RootState,
  seatIdSet: Set<string>,
  cart: Cart | null
): Set<string> {
  if (!cart) {
    return Set();
  }

  const availableSeatMappedBySeat = getAvailableSeatMappedBySeatId(state);
  const selectedSeatIdSet = state.seating.get('selectedSeatIdSet');

  const cartAvailableSeatList = getAvailableSeatListFromCart(cart);

  const availableSeatSeatIdSet = cartAvailableSeatList
    .map((avs) => {
      assertRelationIsObject(avs.seat, 'availableSeat.seat');

      const seatId = avs.seat['@id'];

      assertRelationIsString(seatId, 'availableSeat.seat.@id');

      return seatId;
    })
    .toSet();

  if (selectedSeatIdSet.size === 0) {
    return availableSeatSeatIdSet;
  }

  const movingSeatIdList = selectedSeatIdSet.map(
    (movingSeatId: string | number) => `/v1/seats/${movingSeatId}`
  );

  // proxy for now, but `getPossibleSeatGroupListForBookedSeat` should only handle carts
  // there is another call in `seatSelectable` for now (but only used in OrderViewer context too)
  const bookingCollection = new PagedCollection<Booking>({
    'hydra:member': [],
  }).setIn(
    ['hydra:member', 0],
    /** @ts-expect-error -- @see https://github.com/mapado/ticketing-js-sdk/issues/250  */
    new Booking({
      cart,
      order: null,
    })
  );

  const possibleSeatGroupListForCartItem =
    getPossibleSeatGroupListForBookedSeat(bookingCollection, movingSeatIdList);

  const selectedStockContingentIdsFromCartAvailableSeatList =
    cartAvailableSeatList
      ?.filter(({ seat, stockContingent }) => {
        // filter to keep only selected seats with stockContingent
        assertRelationIsObject(seat, 'seat');
        assertRelationIsNullOrObject(stockContingent, 'stockContingent');

        const stockContingentId = stockContingent?.get('@id');

        return selectedSeatIdSet.has(+seat.getShortId()) && !!stockContingentId;
      })
      .map(({ stockContingent }) => {
        // return stockContingent ids
        assertRelationIsNullOrObject(stockContingent, 'stockContingent');

        return stockContingent?.get('@id');
      });

  const allFreeSeatList = seatIdSet.filter((seatId) => {
    const availableSeat = availableSeatMappedBySeat.get(seatId);

    if (!availableSeat) {
      throw new TypeError(
        'availableSeat should be defined. This should not happen'
      );
    }

    if (selectedStockContingentIdsFromCartAvailableSeatList.size === 0) {
      // no stockContingent in selection
      return (
        !availableSeat.stockContingent &&
        availableSeat.bookingStatus === BOOKING_STATUS_AVAILABLE
      );
    }

    const availableSeatStockContingentId = getEntityId(
      availableSeat?.stockContingent
    );

    return (
      // availableSeat match stockContingent from selection
      selectedStockContingentIdsFromCartAvailableSeatList.includes(
        // @ts-expect-error weird type error due to IRI id type more stricter (https://github.com/mapado/ticketing-js-sdk/pull/482)
        availableSeatStockContingentId
      ) && availableSeat.bookingStatus === BOOKING_STATUS_AVAILABLE
    );
  });

  const availableFreeSeatList = allFreeSeatList.filter((seatId) => {
    let destinationSeatGroupId: null | string = null;

    const seatConfig = state.seating.get('seatConfig');

    if (!seatConfig) {
      throw new Error(
        'SeatConfig should be set in store. Maybe you forgot to check that `app.loadStatus.seatConfigData` is set'
      );
    }

    const availableSeat = availableSeatMappedBySeat.get(seatId);

    if (availableSeat && availableSeat.seatGroup) {
      destinationSeatGroupId =
        typeof availableSeat.seatGroup === 'string'
          ? availableSeat.seatGroup
          : availableSeat.seatGroup['@id'];
    }

    // check if current seatGroup is among possible seat groups
    const seatIsAvailableForCartItem =
      possibleSeatGroupListForCartItem.findIndex(
        (seatGroup: string | SeatGroup) => {
          return getEntityId(seatGroup) === destinationSeatGroupId;
        }
      );

    return seatIsAvailableForCartItem >= 0;
  });

  return seatIdSet.filter(
    (seatId) =>
      // @ts-expect-error weird type error due to IRI id type more stricter (https://github.com/mapado/ticketing-js-sdk/pull/482)
      availableSeatSeatIdSet.has(seatId) || availableFreeSeatList.has(seatId)
  );
}

export function getSelectableSeatIdSetForSeatingPlanPurchase(
  state: RootState,
  seatIdSet: Set<string>,
  currentCartAvailableSeatList: List<AvailableSeat>,
  removedAvailableSeatList: List<AvailableSeatType>
): Set<string> {
  const availableSeatMappedBySeat = getAvailableSeatMappedBySeatId(state);

  return seatIdSet.filter((seatId) => {
    const availableSeat = availableSeatMappedBySeat.get(seatId);

    if (!availableSeat) {
      return false;
    }

    if (availableSeat.status === AVAILABLE_SEAT_STATUS_AVAILABLE) {
      return true;
    }

    const checkForSelectableRemovedSeat = removedAvailableSeatList
      .map((removedAvailableSeat) => removedAvailableSeat['@id'])
      .includes(availableSeat['@id']);

    if (checkForSelectableRemovedSeat) {
      return true;
    }

    return (
      currentCartAvailableSeatList
        .map((innerAvailableSeat) => innerAvailableSeat.get('@id'))
        // @ts-expect-error weird type error due to IRI id type more stricter (https://github.com/mapado/ticketing-js-sdk/pull/482)
        .includes(availableSeat['@id'])
    );
  });
}

export function getSelectableSeatIdSetForOrderManagement(
  state: RootState,
  seatIdSet: Set<string>
): Set<string> {
  const availableSeatMappedBySeat = getAvailableSeatMappedBySeatId(state);

  return seatIdSet.filter((seatId) => {
    const seat = availableSeatMappedBySeat.get(seatId);

    return (
      seat &&
      seat?.status !== BOOKING_STATUS_AVAILABLE &&
      seat?.status !== BOOKING_STATUS_DISMISSED &&
      seat?.bookingStatus !== BOOKING_STATUS_IN_CART
    );
  });
}

export const seatByIdSelector = createSelector(
  seatSelector,
  (seatList: List<SeatType> | null): Map<string, SeatType> => {
    return Map(
      seatList?.map((seat) => {
        return [seat['@id'], seat];
      })
    );
  }
);

export const useSeatById = (): Map<string, SeatType> =>
  useSeatingSelector(seatByIdSelector);

export function getSelectableSeatIdSetForContingentPane(
  state: RootState,
  seatIdSet: Set<string>,
  selectedContingent: string | Contingent | null = null
): Set<string> {
  const availableSeatModelList: List<AvailableSeatModelType> | null =
    availableSeatModelSelector(state);

  if (!availableSeatModelList) {
    return Set<string>();
  }

  const availableSeatModelBySeatId = getAvailableSeatModelMappedBySeatId(state);

  return seatIdSet.filter((seatId) => {
    const availableSeatModel = availableSeatModelBySeatId.get(seatId);

    if (!availableSeatModel) {
      return false;
    }

    if (!availableSeatModel.contingent) {
      return true;
    }

    const contingentId =
      typeof availableSeatModel.contingent === 'string'
        ? availableSeatModel.contingent
        : availableSeatModel.contingent['@id'];

    return (
      selectedContingent !== null &&
      contingentId === getEntityId(selectedContingent)
    );
  });
}

export function getSelectableSeatIdSetForStockManagementContingent(
  state: RootState,
  seatIdSet: Set<string>,
  selectedStockContingent: string | StockContingent | null = null
): Set<string> {
  if (eventDateSelector(state)) {
    const availableSeatMappedBySeat = getAvailableSeatMappedBySeatId(state);

    return seatIdSet.filter((seatId) => {
      const availableSeat = availableSeatMappedBySeat.get(seatId);

      if (!availableSeat) {
        throw new TypeError(
          'availableSeat should be defined. This should not happen'
        );
      }

      if (!availableSeat.stockContingent) {
        return true;
      }

      return (
        selectedStockContingent !== null &&
        getEntityId(availableSeat.stockContingent) ===
          getEntityId(selectedStockContingent)
      );
    });
  }

  const seatList = seatByIdSelector(state);

  return seatIdSet.filter((seatId) => {
    const seat = seatList.get(seatId);

    if (!seat) {
      return false;
    }

    if (!seat.stockContingent) {
      return true;
    }

    return (
      selectedStockContingent !== null &&
      getEntityId(seat.stockContingent) === getEntityId(selectedStockContingent)
    );
  });
}

export function getSelectableSeatIdSetForStockManagementSeatGroup(
  state: RootState,
  seatIdSet: Set<string>
): Set<string> {
  const availableSeatMappedBySeat = getAvailableSeatMappedBySeatId(state);

  return seatIdSet.filter((seatId) => {
    const seat = availableSeatMappedBySeat.get(seatId);

    return seat && seat.status === AVAILABLE_SEAT_STATUS_AVAILABLE;
  });
}

export function getSelectableSeatIdSetForStockManagementGauge(
  state: RootState,
  seatIdSet: Set<string>
): Set<string> {
  const availableSeatMappedBySeat = getAvailableSeatMappedBySeatId(state);

  return seatIdSet.filter((seatId) => {
    const seat = availableSeatMappedBySeat.get(seatId);

    return (
      (seat && seat?.status === AVAILABLE_SEAT_STATUS_AVAILABLE) ||
      seat?.status === AVAILABLE_SEAT_STATUS_DISMISSED
    );
  });
}

export type GetSelectableSeatIdSetFunction = (
  state: RootState,
  seatIdSet: Set<string>,
  isDrawing?: boolean
) => Set<string>;

export function getSelectableSeatIdSet(
  state: RootState,
  getSelectableSeatIdSetForContext: GetSelectableSeatIdSetFunction
): Set<string> {
  const seatIdList = (state.seating.get('seatList') || List())
    .map((s) => s['@id'])
    .toSet();

  return getSelectableSeatIdSetForContext(state, seatIdList);
}

export function isTicketPriceAvailableOnThisSeatGroup(
  seatEntity: AvailableSeatType,
  cart: Cart | null,
  setState: (state: object) => void
) {
  assertRelationIsObject(seatEntity.seatGroup, 'seatEntity.seatGroup');
  assertRelationIsDefined(cart, 'cart');

  const availableSeatList = getAvailableSeatListFromCart(cart);

  const availableSeat = availableSeatList.first();

  assertRelationIsDefined(availableSeat, 'availableSeat');
  assertRelationIsObject(availableSeat.seatGroup, 'availableSeat.seatGroup');

  setState({
    oldCategory: availableSeat.seatGroup.label,
    newCategory: seatEntity.seatGroup.label,
  });

  assertRelationIsObject(seatEntity.seatGroup, 'seatEntity.seatGroup');
  assertRelationIsObject(cart.cartItemList, 'cart.cartItemList');

  const cartItem = cart.cartItemList.first();

  assertRelationIsObject(cartItem, 'cartItem');
  assertRelationIsObject(cartItem.ticketPrice, 'cartItem.ticketPrice');
  assertRelationIsObject(
    cartItem.ticketPrice.ticketPriceSeatGroupList,
    'cartItem.ticketPrice.ticketPriceSeatGroupList'
  );

  const isSamePrice = cartItem.ticketPrice.ticketPriceSeatGroupList.some(
    (ticketPriceSeatGroup) => {
      assertRelationIsObject(ticketPriceSeatGroup, 'ticketPriceSeatGroup');
      assertRelationIsDefined(
        ticketPriceSeatGroup.seatGroup,
        'ticketPriceSeatGroup.seatGroup'
      );
      assertRelationIsObject(seatEntity.seatGroup, 'seatEntity.seatGroup');

      return ticketPriceSeatGroup.seatGroup === seatEntity.seatGroup['@id'];
    }
  );

  return isSamePrice;
}
