import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';

export enum View {
  Contingent = 'contingent',
  SeatGroup = 'seatGroup',
}

export type ViewListType = ReadonlyArray<View>;

type ViewContextType = {
  selectedView: View;
  setSelectedView: Dispatch<SetStateAction<View>>;
  viewList: ViewListType;
};

const ViewContext = createContext<ViewContextType | null>(null);

type Props = {
  children: ReactNode;
  value: ViewListType;
};

export function ViewContextProvider({ children, value }: Props): ReactElement {
  const [selectedView, setSelectedView] = useState(
    value.includes(View.Contingent) ? View.Contingent : value[0]
  );

  const providerValue = useMemo(
    () => ({ viewList: value, selectedView, setSelectedView }),
    [value, selectedView, setSelectedView]
  );

  return (
    <ViewContext.Provider value={providerValue}>
      {children}
    </ViewContext.Provider>
  );
}

export const useViewContext = (): ViewContextType => {
  const context = useContext(ViewContext);

  if (!context) {
    throw new Error('useViewContext must be used within a ViewContextProvider');
  }

  return context;
};
